<script setup lang="ts">
import HistoryList from "@/components/lists/history/HistoryList.vue";
import router from "@/router";
import store from "../store";
import { formatNumberByZiro } from "../tools/tools";
import { date, MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import { computed, onMounted, onUnmounted, ref, nextTick, watch } from "vue";
import { ApiService } from "@/api/apiService";
import levelUp from "@/components/levelup/LevelUp.vue";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const bonusBtn = ref(t(`profile.claim_bonus`));

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

const userService = new UserService;

const currentLevel = ref('1');
const oldLevel = ref(store.getters.USER.oldLevel);
const showLevelUpPopup = ref(false);
const last_bonus_at = ref(store.getters.USER.last_bonus_at);



function updateCurrentLevel(vipStatus = store.getters.USER.vip_status) {
  switch (vipStatus) {
    case 'gold':
      currentLevel.value = '4';
      break;
    case 'silver':
      currentLevel.value = '3';
      break;
    case 'usual':
      currentLevel.value = '2';
      break;
    default:
      currentLevel.value = '1';
  }
  if (currentLevel.value > oldLevel.value) {
    showLevelUpPopup.value = true;
    store.commit('SET_OLD_LEVEL', currentLevel.value);
  }
}

onMounted(async () => {
  await nextTick();

  postEvent('web_app_setup_back_button', { is_visible: true });

  updateCurrentLevel();

  watch(() => store.getters.USER.vip_status, (newStatus) => {
    updateCurrentLevel(newStatus);
  });

  on('back_button_pressed', listener);

  // Инициализируем текст кнопки при монтировании
  updateBonusButton();

  // Устанавливаем интервал для обновления каждую минуту
  const intervalId = setInterval(() => {
    updateBonusButton();
  }, 500); 

  // Очищаем интервал при размонтировании компонента
  onUnmounted(() => {
    clearInterval(intervalId);
    postEvent('web_app_setup_back_button', { is_visible: false });
    off('back_button_pressed', listener);
  });
});

const backgroundClass = computed(() => {
  switch (store.getters.USER.vip_status) {
    case 'gold':
      return 'gradient-gold';
    case 'silver':
      return 'gradient-silver';
    default:
      return 'background-white';
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
      last_bonus_at.value = store.getters.USER.last_bonus_at;
}

function getBonus(){
  if (isMoreThan24HoursAgo(last_bonus_at.value)){
    triggerHapticFeedback('soft');
    userService.getBonus(10000);
    nextTick(()=>{
      updateUser();
    })
    nextTick(()=>{
      updateBonusButton();
    })
  }
}



// Функция для вычисления оставшегося времени до получения бонуса
function getRemainingTime(lastBonusAt: string) {
  const targetDate = new Date(lastBonusAt);
  const currentDate = new Date();
  const diffInMilliseconds = 24 * 60 * 60 * 1000 - (currentDate.getTime() - targetDate.getTime());

  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
}

function isMoreThan24HoursAgo(dateString: string): boolean {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - targetDate.getTime();
  const hours24InMilliseconds = 24 * 60 * 60 * 1000;

  return diffInMilliseconds > hours24InMilliseconds;
}

// Функция для обновления текста кнопки с обратным отсчетом
const updateBonusButton = () => {
  updateUser();
    nextTick(()=>{
        if (isMoreThan24HoursAgo(last_bonus_at.value)) {
        bonusBtn.value = t(`profile.claim_bonus`);
      } else {
        const remainingTime = getRemainingTime(last_bonus_at.value);
        
        // Если осталось больше часа, показываем часы и минуты
        if (remainingTime.hours > 0) {
          bonusBtn.value = `${remainingTime.hours}h ${remainingTime.minutes}min`;
        } else {
          // Если остались только минуты, показываем только их
          bonusBtn.value = `${remainingTime.minutes}min`;
        }
      }
    })
  };

const buttonStyle = computed(() => {
  if (isMoreThan24HoursAgo(last_bonus_at.value)) {
    return {
      backgroundColor: '#5C4E73', // Цвет кнопки, если можно получить бонус
    };
  } else {
    return {
      backgroundColor: '#6E6976', // Цвет кнопки, если бонус еще не доступен
    };
  }
});

watch(last_bonus_at, () => {
  updateBonusButton();
});


</script>

<template>
   <level-up v-if="showLevelUpPopup"/>
  <div v-else>
    <div class="logo-wrapper">
      <img src="@/assets/logo/logo.svg" class="no-image-animation">
    </div>
    <div class="user-info">
      <div class="user-container">
        <img class="user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
        <div :class="['user-logo-wrapper', backgroundClass]">
          <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
        </div>
      </div>
      <div class="user-info-main springy-animation">
        <p class="user-info__username">{{ userData.user.username ?? "noname" }}</p>
        <div class="user-info__balance springy-animation">
          <p class="user-info__balance-label">{{ t(`profile.balance`) }}</p>
          <p class="user-info__balance-money">{{ formatNumberByZiro(store.getters.USER.balance) }}</p>
          <img src="@/assets/logo/money_logo.svg" class="user-info__balance-money-logo">
        </div>
      </div>
    </div>
    <div class="bonus-wrapper">
      <div class="bonus-wrapper-up">
        <p>{{ t(`profile.daily_bonus`) }}</p>
      </div>
      <div class="bonus-wrapper-center">
        <p class="ten-k">+10 000</p>
        <img src="@/assets/logo/money_logo.svg" class="red-coin">
      </div>
      <div class="bonus-wrapper-dwn" :style="buttonStyle" @click="getBonus()">
        {{ bonusBtn }}
      </div>
      <img src="@/assets/logo/logo-background-2.svg" class="background-logo no-image-animation">
    </div>
    <div class="bonus-wrapper">
      <div class="bonus-wrapper-center-box">
        <p class="box-text">{{ t(`profile.box`) }}</p>
      </div>
      <div class="bonus-wrapper-dwn-box" @click="triggerHapticFeedback('soft'); router.push('/friends')">
        {{ t(`profile.friends`) }}
        <img src="@/assets/friends/friend.svg">
      </div>
      <img src="@/assets/friends/open-red-box-dark.svg" class="box-img">
      <img src="@/assets/logo/logo-background-2.svg" class="background-logo no-image-animation">
    </div>
    <!-- <div class="levels-and-balance-actions">
      <div class="levels-button" @click="triggerHapticFeedback('light'); router.push('/levels')">
        <p class="levels-text">{{ t(`profile.levels`) }}</p>
      </div>
      <div class="balance-actions">
        <div class="top-up-button" @click="triggerHapticFeedback('light'); router.push('/profile/top-up')">
          <p class="balance-action-text">{{ t(`profile.deposit`) }}</p>
          <div class="balance-topup-main">
            <p class="balance-topup-main-plus">+</p>
          </div>
        </div>
        <div class="withdraw-button" @click="triggerHapticFeedback('light'); router.push('/profile/withdraw')">
          <p class="balance-action-text">{{ t(`profile.withdraw`) }}</p>
          <div class="balance-topup-main">
            <p class="balance-topup-main-plus">-</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="all-transaction" @click="pushToTransactions">
      <div>
        <p class="balance-action-text">{{ t(`profile.transaction_history`) }}</p>
      </div>
      <div class="all-transaction-arrow">
        <img src="@/assets/tools/all-transaction-arrow.svg">
      </div>
    </div> -->
    <div class="game-history">
      <history-list/>
    </div>
  </div>
</template>

<style scoped>

.bonus-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 391px;
  border-radius: var(--border-mixed);
  background-color: #15151E;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-top: 20px;
  overflow: hidden;
  max-height: 226px;
}

.bonus-wrapper-up {
  width: 100%;
  text-align: left;
  color: #B9B9B9;
  font-size: 18px;
  font-weight: 500;
}

.bonus-wrapper-center {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 30px;
  font-weight: 700;
  gap: 3.95vw;
}

.bonus-wrapper-dwn {
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  border-radius: var(--border-mixed);
  z-index: 2;
}

.bonus-wrapper-dwn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  background-color: #977BCD;
  border-radius: var(--border-mixed);
  z-index: 2;
  gap: 21px;
  z-index: 4;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.bonus-wrapper-center-box {
  width: 100%;
  margin-top: 58px;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 5;
}

.box-text {
  max-width: 160px;
  font-size: 17px;
  text-align: start;
}

.box-img {
  position: absolute;
  z-index: 3;
  animation: none;
  transform: rotate(14deg) scale(-1, 1);
  filter: drop-shadow(-2px 19px 18px rgba(0, 0, 0, 0.25));
  width: 186px;
  left: -20px;
  top: -7px;
}

.gradient-gold {
  background: var(--gradient-gold);
}

.gradient-silver {
  background: var(--gradient-sliver);
}

.background-white {
  background: white;
}

.logo-wrapper {
  margin-top: 3%;
}

.user-logo {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  text-align: center;
  margin-left: 3px;
  filter: drop-shadow(0px 10px 125px rgba(118, 81, 140, 0.4));
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.user-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info-main {
  width: 100%;
  text-align: left;
  margin-top: 15px;
}

.user-info__username {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.user-info__balance {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
}

.user-info__balance-label {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}

.user-info__balance-money {
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}

.user-info__balance-money-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
}

.all-transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.5vh;
  border-radius: var(--border-mixed);
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
}
.all-transaction-arrow{
  margin-right: 25px;
}

.balance-actions {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
}

.levels-button{
  background-size: cover;
  background-image: url(/src/assets/background/background-levels.svg);
  border-radius: var(--border-mixed);
  flex-direction: column;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: #15151E;
  text-align: left;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-right: 14px;
  flex-basis: 40%;
}

.top-up-button {
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-bottom: 14px;
  flex-basis: 50%;
}

.withdraw-button {
  border-radius: var(--border-mixed);
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  flex-basis: 50%;
}

.balance-action-text {
  font-weight: 500;
  font-size: 18px;
  margin-left: 15px;
}

.levels-text{
  font-weight: 500;
  font-size: 18px;
  margin-left: 18px;
}

.game-history {
  margin-top: 46px;
  margin-bottom: 100px;
}

.user-logo-wrapper {
  border-radius: 50%;
  width: 116px;
  height: 116px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.user-status {
  height: 24px;
}

.levels-and-balance-actions{
  height: 28vh;
  display: flex;
  margin-top: 20px;
}

.balance-topup-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #A5A5A6;
  width: 40px;
  height: 40px;
  z-index: 5;
  margin-right: 15px;
}

.balance-topup-main-plus {
  text-align: center;
  color: #A5A5A6;
}

</style>