<script setup lang="ts">
import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import {ApiService} from "@/api/apiService";
import {computed, defineProps, watch, ref, onMounted} from "vue";
import CountTimer from "@/components/game/CountTimer.vue";
import {formatNumber} from "../../tools/tools";
import EmojiPlayerItem from "@/components/game/EmojiPlayerItem.vue";

const props = defineProps<{
  selectedEmoji: any,
  game: Game,
  player: Player,
  timer: number,
  showPlayer: boolean
  selectedEmojiPlayerID: number,
  winners: any[],
  currentCase: string | null;
}>();

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const userBetText = ref(formatNumber(store.getters.USER.balance));
const transformStyle= ref('');

watch(
  () => props.currentCase,
  (newCase) => {
    const isCurrentPlayerWinner = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);

    if (newCase === 'show_down' && isCurrentPlayerWinner) {
      userBetText.value = 'win';
      transformStyle.value = 'transform: scale(1.1); filter: drop-shadow(0px 0px 5px rgba(240, 190, 121));';
    }
  }
);

const userData = window.Telegram.WebApp.initDataUnsafe;

const borderColor = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return 'white';
    case "silver":
      return "var(--gradient-sliver)";
    case "gold":
      return "var(--gradient-gold)";
    default:
      return 'white';
  }
});

const avatarUrl = ref('');

const getCachedAvatarUrl = () => {
  const cachedAvatar = localStorage.getItem('userAvatarUrl');
  if (cachedAvatar && avatarUrl.value !== cachedAvatar) {
    avatarUrl.value = cachedAvatar;
  } else {
    updateAvatarUrl();
  }
};

const updateAvatarUrl = () => {
  const photoUrl = store.getters.USER.photo_url;
  if (photoUrl && avatarUrl.value !== ApiService.url + photoUrl) {
    avatarUrl.value = ApiService.url + photoUrl;
    localStorage.setItem('userAvatarUrl', avatarUrl.value);
  } else if (!photoUrl) {
    avatarUrl.value = require('@/assets/mock_user/user.svg');
  }
};

watch(() => store.getters.USER.photo_url, (newPhotoUrl) => {
  if (newPhotoUrl && avatarUrl.value !== ApiService.url + newPhotoUrl) {
    updateAvatarUrl();
  }
});

onMounted(() => {
  getCachedAvatarUrl();
});

const isCurrentPlayerWinner = computed(() => {
  return Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);
});
</script>

<template>
  <div v-if="props.game?.current_player_id">
    <div :class="['game-user-player', {'not-watcher': props.showPlayer }]" :style="transformStyle">
      <div class="game-user-image-wrapper">
        <div class="dealer-button" v-if="props.player?.id === props.game.dealer_id">
          <img class="dealer-button-button" :src="require(`@/assets/dealer-buttons/btn-dealer-${store.getters.USER.vip_status}.svg`)">
        </div>
        <div class="timer-player">
          <count-timer :timer="timer" :game="game" :player="player" />
        </div>
        <div class="emoji-item-user">
          <emoji-player-item
            :selected-emoji="selectedEmoji"
            :selectedEmojiPlayerID="selectedEmojiPlayerID!"
            :seat="seat"
            :showPlayer="showPlayer"
          />
        </div>
        <div class="game-user-image-border no-image-animation">
          <div class="user-corona">
            <img v-if="store.getters.USER.vip_status != 'first'"
                 class="user-corona no-image-animation"
                 :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" />
          </div>
          <div class="game-user-image-1">
            <img class="game-user-image no-image-animation"
                 :style="{ 'background': borderColor }"
                 :src="avatarUrl" />
          </div>
          <div class="user-username-bet-wrapper">
            <div v-if="isCurrentPlayerWinner"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center', marginRight: '0' }">
                {{ userBetText }}
              </div>
            </div>
            <div v-else-if="!isCurrentPlayerWinner && !props.showPlayer"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center'}">
                {{ formatNumber(store.getters.USER.balance) }}
              </div>
            </div>
            <div v-else class="user-balance no-image-animation"
                 :style="{ justifyContent: 'space-between' }">
              <div class="user-game-deposit">
                {{ formatNumber(props.player.game_deposit) }}
              </div>
              <div class="game-user-balance">
                {{ formatNumber(store.getters.USER.balance) }}
              </div>
            </div>
            <div class="user-username no-image-animation">
              {{ userData.user.username ?? 'noname' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div :class="['game-user-player', {'not-watcher': props.showPlayer }]"
         :style="transformStyle">
      <div class="game-user-image-wrapper">
        <div class="timer-player">
          <count-timer :timer="timer" :game="game" :player="player" />
        </div>
        <div class="emoji-item-user">
          <emoji-player-item
            :selected-emoji="selectedEmoji"
            :selectedEmojiPlayerID="selectedEmojiPlayerID!"
            :seat="seat"
            :showPlayer="showPlayer" />
        </div>
        <div class="game-user-image-border no-image-animation">
          <div class="user-corona">
            <img v-if="store.getters.USER.vip_status != 'first'"
                 class="user-corona no-image-animation"
                 :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" />
          </div>
          <div class="game-user-image-1">
            <img class="game-user-image no-image-animation"
                 :style="{ 'background': borderColor }"
                 :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')" />
          </div>
          <div class="user-username-bet-wrapper">
            <div v-if="isCurrentPlayerWinner"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center', marginRight: '0' }">
                {{ userBetText }}
              </div>
            </div>
            <div v-else-if="!isCurrentPlayerWinner && !props.showPlayer"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center'}">
                {{ formatNumber(store.getters.USER.balance) }}
              </div>
            </div>
            <div v-else class="user-balance no-image-animation"
                 :style="{ justifyContent: 'space-between' }">
              <div class="user-game-deposit">
                {{ formatNumber(props.player.game_deposit) }}
              </div>
              <div class="game-user-balance">
                {{ formatNumber(store.getters.USER.balance) }}
              </div>
            </div>
            <div class="user-username no-image-animation">
              {{ userData.user.username ?? 'noname' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<style scoped>

.user-game-deposit {
  width: 100%;
  text-align: start;
  margin-left: 1vw;
}

.game-user-balance {
  width: 100%;
  text-align: end;
  font-weight: 600;
  margin-right: 1vw;
}

.dealer-button {
  position: absolute;
  z-index: 101;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  right: 0;
  top: 12px;
}

.emoji-item-user{
  position: absolute;
  top: 65%;
  left: 90%;
  z-index: 2;
}


.dealer-button-button {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
} 

.timer-player {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 21px;
  left: 4px;
  top: 22px;
  transform: scale(1.2);
}

.game-user-image-1 {
  width: 100%;
}

.game-user-image {
  padding: 3px;
  border-radius: 50%;
  width: 11.3vh;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
  background: borderColor;
}

.user-hand-wrapper {
  margin-top: 7%;
  margin-right: 8%;
  text-align: right;
  width: 45%;
}

.player-cards-wrapper {
  position: relative;
  z-index: 2;
}
.game-user-image-wrapper {
  position: relative;
  z-index: 3;
  padding: 2px;
}

.game-user-image-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.user-username-bet-wrapper {
  z-index: 2;
  margin-top: -20px;
  width: 100%;
}

.user-username {
  font-size: 1.4vh;
  font-weight: 500;
  z-index: 2;
}

.user-balance {
  font-size: 1.5vh;
  padding: 0.5vh;
  background: #5C4E73;
  box-shadow: 0 5px 62px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  display: flex;
}

.user-corona {
  height: 3vh;
  width: 100%;
  margin-bottom: 6px;
}

.join-table {
  background: #5C4E73;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: center;
  margin-top: 0.54vh;
}
</style>