<script setup lang="ts">
import { onMounted, ref, watch, defineProps, onUnmounted } from 'vue';
import lottie from 'lottie-web';
import { ref as vueRef } from 'vue'; 
import Player from '@/models/player';

import like_Data from '@/assets/game/emoji/Positive/Like.json';
import fire_Data from '@/assets/game/emoji/Positive/Fire.json';
import boomstick_Data from '@/assets/game/emoji/Positive/Boomstick.json';
import star_Data from '@/assets/game/emoji/Positive/Star.json';
import flexed_bicep_Data from '@/assets/game/emoji/Positive/Flexed_biceps.json';

import thumb_down_Data from '@/assets/game/emoji/Negative/Thumb_down.json';
import angry_Data from '@/assets/game/emoji/Negative/Angry.json';
import skull_Data from '@/assets/game/emoji/Negative/Skull.json';
import water_face_Data from '@/assets/game/emoji/Negative/Water_face.json';
import super_angry_Data from '@/assets/game/emoji/Negative/Super_angry.json';

import ok_Data from '@/assets/game/emoji/Neutral/Ok.json';
import thinking_face_Data from '@/assets/game/emoji/Neutral/Thinking_face.json';
import pokerface_Data from '@/assets/game/emoji/Neutral/pokerface.json';
import monokle_Data from '@/assets/game/emoji/Neutral/Monokle.json';
import mda_Data from '@/assets/game/emoji/Neutral/Mda.json';

import bye_Data from '@/assets/game/emoji/Closing/Bye.json';
import folded_hands_Data from '@/assets/game/emoji/Closing/Folded_hands.json';
import call_me_Data from '@/assets/game/emoji/Closing/Call_me.json';
import bye_mars_Data from '@/assets/game/emoji/Closing/Bye_mars.json';
import peace_Data from '@/assets/game/emoji/Closing/Peace.json';


const emojiSets = {
  Like: like_Data,
  Fire: fire_Data,
  Boomstick: boomstick_Data,
  Star: star_Data,
  Flexed_bicep : flexed_bicep_Data,
  Thumb_down: thumb_down_Data,
  Angry : angry_Data,
  Skull : skull_Data,
  Water_face : water_face_Data,
  Super_angry : super_angry_Data,
  Ok : ok_Data,
  Thinking_face: thinking_face_Data,
  Pokerface : pokerface_Data,
  Monokle : monokle_Data,
  Mda : mda_Data,
  Bye : bye_Data,
  Folded_hands : folded_hands_Data,
  Call_me : call_me_Data,
  Bye_mars : bye_mars_Data,
  Peace : peace_Data
}

type Seat = {
  player: Player | null,
  seat: number
}

const props = defineProps<{
  selectedEmoji: any,
  selectedEmojiPlayerID: number,
  seat:Seat
}>();

const container = ref<HTMLDivElement | null>(null);
let animationInstance: any = null;
const animationClass = vueRef(''); 
const isClosing = vueRef(false); 

onUnmounted(() => {
  if (animationInstance) {
    animationInstance.destroy();
  }
});

watch(() => props.selectedEmoji, (newEmoji) => {
  if (animationInstance) {
    animationInstance.destroy();
    animationInstance = null;
  }
  if (container.value && newEmoji && props.selectedEmojiPlayerID === props.seat.player?.user_id) {
    isClosing.value = false;
    animationClass.value = 'scale-in'; 
    let item = emojiSets[newEmoji];
    animationInstance = lottie.loadAnimation({
      container: container.value,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: item
    });
      setTimeout(() => {
        isClosing.value = true; 
        console.log(container.value);
      }, 2000); 
  }
});
</script>

<template>
  <div :class="['emoji-player-item', animationClass, { 'scale-out': isClosing }]" ref="container"></div>
</template>

<style scoped>
.emoji-player-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 5px;
  border-radius: 30px;
  width: 8.14vw;
  height: 8.14vw;
}

.scale-in {
  animation: scaleIn 0.2s ease forwards;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1.2, 1.2);
    opacity: 1;
  }

  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.scale-out {
  animation: scaleOut 0.2s ease forwards;
}

@keyframes scaleOut {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2, 1.2);
    opacity: 1;
  }

  to {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
}
</style>