
<script setup lang="ts">
import NavBar from "@/components/navigation/NavBar.vue";
import { onMounted, ref, provide } from "vue";
import router from "@/router";
import { UserService } from "@/api/userService";
import store from "@/store";
import { postEvent } from "@telegram-apps/sdk";
import TopUpModal from "@/components/modals/TopUpModal.vue";

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

async function loadSound(url: string): Promise<AudioBuffer | undefined> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok for ${url}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);
    return decodedData;
  } catch (error) {
    console.error(`Error loading sound from ${url}:`, error);
  }
}

function playSound(buffer: AudioBuffer | undefined) {
  if (!buffer) {
    return;
  }

  try {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0);
  } catch (error) {
    console.error("Error playing sound:", error);
  }
}

let soundBuffer: AudioBuffer | undefined;
let checkSoundBuffer: AudioBuffer | undefined;
let flopSoundBuffer: AudioBuffer | undefined;
let backMusicBuffer: AudioBuffer | undefined;

function playbackMusic() {
  if (store.getters.isMusicOn) {
    if (backMusicBuffer) {
      playSound(backMusicBuffer);
    } else {
      console.warn("backMusic buffer is not loaded");
    }
  }
}

function playGlobalSound() {
  if (store.getters.isSoundOn) {
    if (soundBuffer) {
      playSound(soundBuffer);
    } else {
      console.warn("Global sound buffer is not loaded");
    }
  }
}

function playCheckSound() {
  if (store.getters.isSoundOn) {
    if (checkSoundBuffer) {
      playSound(checkSoundBuffer);
    } else {
      console.warn("Check sound buffer is not loaded");
    }
  }
}

function playFlopSound() {
  if (store.getters.isSoundOn) {
    if (flopSoundBuffer) {
      playSound(flopSoundBuffer);
    } else {
      console.warn("Flop sound buffer is not loaded");
    }
  }
}

const isLoaded = ref(false);

provide("playCheckSound", playCheckSound);
provide("playSound", playGlobalSound);
provide("playFlopSound", playFlopSound);

function loadImage(imageUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => resolve();
    img.onerror = (error) => reject(error);
  });
}

import soundUrl from '@/assets/sounds/new_audio.mp3';
import checkSoundUrl from '@/assets/sounds/sound_check.mp3';
import flopSoundUrl from '@/assets/sounds/flop2.mp3';
import backMusic from '@/assets/sounds/flop2.mp3';

onMounted(async () => {
  soundBuffer = await loadSound(soundUrl);
  checkSoundBuffer = await loadSound(checkSoundUrl);
  flopSoundBuffer = await loadSound(flopSoundUrl);
  backMusicBuffer = await loadSound(backMusic);

  postEvent('web_app_expand');
  postEvent('web_app_set_header_color', { color: '#2A2B37' });
  postEvent('web_app_set_background_color', { color: '#2A2B37' });

  router.push('/');

  playbackMusic();

  window.Telegram.WebApp.disableVerticalSwipes();

  const hash = window.location.hash.slice(1);
  const params = new URLSearchParams(hash);
  const kentId = params.get('tgWebAppStartParam');

  const userService = new UserService();
  userService.get(kentId)
    .then(response => response.json())
    .then(data => {
      store.commit('SET_USER', data);
      loadImage(store.getters.USER.photo_url ?? require('@/assets/mock_user/user.svg'));
      isLoaded.value = true;
    });

  userService.getReferrals()
    .then(response => response.json())
    .then(data => {
      store.commit('SET_USER_REFERRALS', data);
    });
});
</script>

<template>
  
  <div v-if="isLoaded">
    <router-view :key="$route.fullPath"/>
    <nav-bar/>
  </div>
  <div v-else class="download-wrapper">
    <div class="download-image-wrapper">
      <img src="@/assets/tools/rolling.svg" class="rolling">
    </div>
  </div>
  <top-up-modal/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --backround-color: #2A2B37;
  --border-mixed: 24px;
  --gradient-sliver: linear-gradient(#989898 0%, #D3D3D3 10%, #878787 17%, #D3D3D3 24%, #A5A5A5 40%, #767676 57%, #606060 72%, #D3D3D3 85%, #606060 99%);
  --gradient-gold: linear-gradient(#E3A355 10%, #F6DBA6 24%, #FFEBC3 40%, #F0BE79 57%, #BA7F3B 72%, #EEBC70 85%, #89571D 99%);

  --gradient-gold-thumb: linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%);
  --gradient-silver-thumb: linear-gradient(240.24deg, #989898 5.73%, #DBDBDB 39.14%, #C0C0C0 55.44%, #878787 74.76%, #D8D7D7 92.49%, #606060 133.37%), linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%), #CBC0DF;
}

* {
  margin: 0;
  padding: 0;
}

html {
  background-color: #2A2B37;
}

body {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: #FFFFFF;
  min-height: 100vh;
  background: url("@/assets/background/background.svg") repeat-y center top;
  background-size: 100% auto;
}

.no-image-animation {
  animation: none;
}

.springy-animation {
  animation-name: spring;
  animation-duration: .1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes spring {
  from {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes moveCard {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes flipCard {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg)
  }
}

body{
  overflow: hidden;
  height: 100vh;
}

#app::-webkit-scrollbar {
  display: none;
}

#app {
  overflow-y:scroll;
  overflow-x: hidden;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  text-align: center;
  flex-direction: column;
}

#ton-connect {
  display: none;
}

.download-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rolling {
  width: 25%;
}

.download-image-wrapper {
  display: flex;
  justify-content: center;
}

@keyframes half-flip-1 {
  0% {
    transform: rotateZ(-8deg) rotateY(0);
  }
  50%{
    transform: rotateZ(-8deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(-8deg) rotateY(0);
  }
}

@keyframes half-flip-2 {
  0% {
    transform: rotateZ(17deg) rotateY(0);
  }
  50%{
    transform: rotateZ(17deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(17deg) rotateY(0);
  }
}

</style>